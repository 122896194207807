
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Admin } from '@/modules/admin'
import { IUsersFilter, IUsersSearch } from '../types';
import { IUser, ITableHeader, IPagination } from '../../system/types';

@Component
export default class UsersView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  admin = $app.module(Admin);

  loading = false;

  filter: IUsersFilter = $app.clone(this.admin.$store.UsersSearch.filter);

  addItem = false;
  addLogin = '';
  addName = '';

  showItem = false;
  processItem = false;
  selectedItem: IUser | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: '',
        value: 'UserState',
        align: 'left',
        width: '5%'
      },
      {
        text: $app.i18n('system.Login'),
        value: 'UserLogin',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'UserName',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('admin.security.UserEmail'),
        value: 'UserEmail',
        align: 'left',
        width: '35%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.PatientPhone'),
        value: 'UserPhone',
        align: 'left',
        width: '15%'
      }
    ];
  }

  get search(): IUsersSearch {
    return this.admin.$store.UsersSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.admin.$store.findUsers(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.admin.$store.UsersSearch, this.filter);
    this.admin.$store.setUsersSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IUser) {
    this.processItem = true;
    try {
      await this.admin.$store.storeUser(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IUser) {
    this.processItem = true;
    try {
      await this.admin.$store.removeUser(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IUser) {
    try {
      this.selectedItem = await this.admin.$store.loadUserData(item);
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
  }

  append() {
    this.addLogin = '';
    this.addName = '';
    this.addItem = true;
  }

  async doAppend() {
    this.processItem = true;
    try {
      const newUser = {
        id: undefined,
        name: this.addLogin,
        state: 'b',
        language: 'en',
        fullName: this.addName,
        company: '',
        clinic: '',
        phone: '',
        email: '',
        logo: '',
        background: '',
        toolbarDamage: null,
        taxCode: '',
        profCode: '',
        workNumber: '',
        homePage: '/about',
        rowsPerPage: 25,
        color: undefined,
        companyId: 0,
        companyNVD: '',
        roles: [],
        clinics: [],
        totalIncome: 0,
        totalIncomeNVD: 0,
        totalDebt: 0,
        processing: false
      };

      this.selectedItem = await this.admin.$store.storeUser(newUser);
      this.addItem = false;
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
